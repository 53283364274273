import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import Plot, { PlotParams } from "react-plotly.js";
//
import { E5CBDashboard } from "../../page/customer_base/dashboard/E5CBDashboard";
import { E5StoreAngularGaugeInfo } from "../../request/E5ServiceCommon";
import { E5MainConfig } from "../E5MainConfig";
import { E5Static } from "../E5MainStatics";
import { E5Text } from "../../util/E5Text";
//
import "./E5AngularGauge.css";
import ReactECharts from 'echarts-for-react';

export interface E5XYChartOption {
	type?: string;
	filled?: boolean;
	colors?: string[];
}

//E5
interface E5AngularGaugeState { }

//E5
interface E5AngularGaugeProps {
	gaugeinfo: E5StoreAngularGaugeInfo;
	isNewComponent?: boolean;
	chartOption?: E5XYChartOption;
	className?: string;
	customWrapperClass?: string;
	customChartClass?: string;
	customListWidth?: string;
	canvasSmall30?: boolean;
	chartNewColorScheme?: boolean;
}

//E5
export const E5AngularGauge = observer(class E5AngularGauge extends React.PureComponent
	<E5AngularGaugeProps, E5AngularGaugeState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		let { value, value2, value3, value4, labels, values, loading } = this.props.gaugeinfo, graphdata: PlotParams = this.GetGraphData(value),
			graphdata2: PlotParams = this.GetGraphData(value2), graphdata3: PlotParams = this.GetGraphData(value3),
			allthree: boolean = ![value, value2, value3].includes(undefined),
			percentstr: string = value === null ?
				"N/A" : value === undefined ? "" : (E5Text.ScoreToPercent(value) + "%"),
			percentstr2: string = value2 === null ?
				"N/A" : value2 === undefined ? "" : (E5Text.ScoreToPercent(value2) + "%"),
			percentstr3: string = value3 === null ?
				"N/A" : value3 === undefined ? "" : (E5Text.ScoreToPercent(value3) + "%"),
			valueclass: string = "e5gauge-value" + (allthree ? " small" : "");


		return <div className={"e5compo e5angular-gauge e5columnfull e5column-5" + (allthree ? " small" : "") + (this.props.customWrapperClass ? ` ${this.props.customWrapperClass}` : "")}>
			{this.props.gaugeinfo.title && <div className="e5compotitle">{this.props.gaugeinfo.title}
				{loading && <BP.Spinner className="e5spinwait" size={15} />}
			</div>}
			{!loading && <div className={`e5columnfull e5-0 ${values?.length ? 'list' : ''} ${this.props.customListWidth ? this.props.customListWidth : ""}`}>
				<div className="e5linefull" />
				{!values?.length && !value && !value2 && !value3 && !value4 && <div className='no-data'>No data collected</div>}
				{values?.map((value: number, index: number) => {
					const label = labels?.[index] || '';
					return <div key={index} className={`e5column-0${this.props.canvasSmall30 ? ' canvas-small-30' : ' canvas-small'}`}>
						<ReactECharts className={this.props.customChartClass ? this.props.customChartClass : ''} option={this.GetOption(value, label, this.props.chartNewColorScheme)} />
					</div>
				})}
				{value && <div className={`e5column-0 ${this.props.chartOption?.type === 'gauge' ? '' : 'canvas'}`}>
					{this.props.isNewComponent
						? <ReactECharts
							className={this.props.customChartClass ? this.props.customChartClass : ""}
							option={this.props.chartOption?.type === 'gauge'
								? this.GetGaugeOption(value)
								: this.GetOption(value, this.props.gaugeinfo.label)}
						/>
						: <>
							<Plot {...graphdata} />
							<div className="e5line-10 e5gauge-labelvalue">
								<div className="e5gauge-label">{this.props.gaugeinfo.label}</div>
								<div className={valueclass}>{percentstr}</div>
							</div>
						</>
					}
				</div>}
				{value2 && <div className={`e5column-0${this.props.canvasSmall30 ? ' canvas-small-30' : ' canvas-small'}`}>

					{this.props.isNewComponent
						? <ReactECharts className="line-chart" option={this.GetOption(value2, this.props.gaugeinfo.label2, this.props.chartNewColorScheme)} />
						: <>
							<Plot {...graphdata2} />
							<div className="e5line-10 e5gauge-labelvalue">
								<div className="e5gauge-label">{this.props.gaugeinfo.label2}</div>
								<div className={valueclass}>{percentstr2}</div>
							</div>
						</>
					}
				</div>}
				{value3 && <div className={`e5column-0${this.props.canvasSmall30 ? ' canvas-small-30' : ' canvas-small'}`}>

					{this.props.isNewComponent
						? <ReactECharts className="line-chart" option={this.GetOption(value3, this.props.gaugeinfo.label3, this.props.chartNewColorScheme)} />
						: <>
							<Plot {...graphdata3} />
							<div className="e5line-10 e5gauge-labelvalue">
								<div className="e5gauge-label">{this.props.gaugeinfo.label3}</div>
								<div className={valueclass}>{percentstr3}</div>
							</div>
						</>
					}
				</div>}
				{value4 && <div className={`e5column-0${this.props.canvasSmall30 ? ' canvas-small-30' : ' canvas-small'}`}>
					<ReactECharts className="line-chart" option={this.GetOption(value4, this.props.gaugeinfo.label4, this.props.chartNewColorScheme)} />
				</div>}
				<div className="e5linefull" />
			</div>}
		</div>;
	}

	// ---------------- UTILS ----------------

	GetGraphData(value: number | undefined | null): PlotParams {
		let allthree: boolean = this.props.gaugeinfo.value !== undefined && this.props.gaugeinfo.value2 !== undefined &&
			this.props.gaugeinfo.value3 !== undefined;

		let needletip: number = allthree ? 65 : 105, needlesin: number = 0, needlecos: number = 0, percent: number = 0;
		if (value === null) {
			needlesin = 0;
			needlecos = 0;
			percent = 0;
		} else if (value !== undefined) {
			needlesin = Math.sin(value * Math.PI);
			needlecos = Math.cos(value * Math.PI);
			percent = E5Text.ScoreToPercent(value);
		}

		let graphdata: PlotParams = {
			data: [
				{
					type: "indicator", mode: "gauge", value: percent,
					gauge: {
						bar: { line: { width: 0 }, thickness: 0 }, bgcolor: "white", bordercolor: "black", borderwidth: 1,
						axis: {
							range: [0, 100], visible: true, tickmode: "array", tickvals: E5Static.gaugethresholds,
							ticks: "inside", ticklen: allthree ? 5 : 7.5, tickwidth: 2, tickcolor: "black",
							showticklabels: true, tickfont: { size: 12, color: "black" }
						},
						steps: [
							{
								color: E5CBDashboard.GetGradientColor(E5MainConfig.GetHealthColorSteps(), percent),
								range: [0, percent]
							}
						]
					}
				}
			],
			layout: {
				shapes: [
					{
						type: "path", layer: "above", xsizemode: "pixel", ysizemode: "pixel", xanchor: 0.5, yanchor: 0,
						fillcolor: "black", path: `M ${needlesin * -7.5} ${needlecos * -7.5} ` +
							`L ${needlecos * -needletip} ${needlesin * needletip} ` +
							`L ${needlesin * 7.5} ${needlecos * 7.5} ` +
							`L ${needlecos * 3.75} ${needlesin * -3.75} Z`
					}
				],
				font: { family: "montserrat" }, width: allthree ? 200 : 300, height: allthree ? 100 : 150,
				margin: { t: 15, r: 30, l: 20, b: 10 }, paper_bgcolor: "#00000000"
			},
			config: { displayModeBar: false }
		};
		return graphdata;
	}

	GetOption(value: number, label: string | undefined, newColorScheme: boolean = false) {
		let colors;
		let isNullValue = false;
		if (value === null) {
			value = 0;
			isNullValue = true;
		}

		if (newColorScheme) {
			colors = isNullValue ? ['gray'] : ['#EA615D', '#F27573', '#FF8D69', '#FFB951', '#FFD553', '#FFF179', '#DDE779', '#B0D684', '#85C988', '#69B36C'];
		} else {
			colors = isNullValue ? ['gray'] : ['#E53935', '#EF5350', '#FF7043', '#FFA726', '#FFCA28', '#FFEE58', '#D4E157', '#9CCC65', '#66BB6A', '#43A047'];
		}
		return {
			tooltip: {
				show: false
			},
			xAxis: {
				max: 100,
				splitLine: { show: false },
				offset: 10,
				axisLine: {
					show: false
				},
				axisLabel: {
					show: false
				}
			},
			yAxis: {
				data: [label],
				inverse: true,
				axisTick: { show: false },
				axisLine: { show: false },
				axisLabel: {
					margin: 10,
					color: 'black',
					fontSize: 16
				}
			},
			grid: {
				height: 0,
				width: 320,
				left: '15%',
			},

			visualMap: {
				orient: 'horizontal',
				show: false,
				min: 0,
				max: 100,
				// Map the score column to color
				dimension: 0,
				inRange: {
					color: colors
				}
			},

			series: [
				{
					// current data
					type: 'pictorialBar',
					tooltip: { trigger: 'none' },
					symbol: 'Rect',
					symbolRepeat: 'fixed',
					symbolMargin: '8%',
					symbolClip: true,
					symbolSize: [14, 40],
					symbolBoundingData: 100,
					data: [E5Text.ScoreToPercent(value)],
					markLine: {
						symbol: 'none',
						label: {
							formatter: 'max: {c}',
							position: 'start'
						}
					},
					z: 10
				},
				{
					// full data
					type: 'pictorialBar',
					tooltip: { trigger: 'none' },
					itemStyle: {
						opacity: 0.2
					},
					label: {
						show: true,
						opacity: 1,
						formatter: function (params: any) {
							if (isNullValue) {
								return "N/A";
							}
							return ((params.value / 100) * 100).toFixed(1) + ' %';
						},
						position: 'right',
						offset: [10, 0],
						color: 'inherit',
						fontSize: 16
					},
					symbolRepeat: 'fixed',
					symbolMargin: '8%',
					symbol: 'Rect',
					symbolSize: [14, 40],
					symbolBoundingData: 100,
					data: [E5Text.ScoreToPercent(value)],
					z: 5
				}
			]
		};
	}

	GetGaugeOption(value: number,) {
		return {
			visualMap: {
				show: false,
				type: 'piecewise',
				bottom: 50,
				left: 'center',
				orient: 'horizontal',
				pieces: [
					{ min: 0, max: 20, color: '#EF5350' },
					{ min: 20, max: 40, color: '#FFA726' },
					{ min: 40, max: 60, color: '#FFEE58' },
					{ min: 60, max: 80, color: '#9CCC65' },
					{ min: 80, max: 100, color: '#66BB6A' }
				],
				calculable: true
			},
			series: [
				{
					type: 'gauge',
					startAngle: 185,
					endAngle: -5,
					center: ['50%', '75%'],
					radius: '90%',
					min: 0,
					max: 100,
					splitNumber: 8,
					axisLine: {
						lineStyle: {
							width: 20,
							color: [
								[0.2, '#EF5350'],
								[0.4, '#FFA726'],
								[0.6, '#FFEE58'],
								[0.8, '#9CCC65'],
								[1, '#66BB6A']
							]
						}
					},
					pointer: {
						show: false
					},
					axisTick: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisLabel: {
						show: false
					},
					title: {
						show: false
					},
					detail: {
						show: false
					}
				},
				{
					type: 'gauge',
					progress: {
						show: true,
						width: 40
					},
					startAngle: 185,
					endAngle: -5,
					center: ['50%', '75%'],
					radius: '79%',
					min: 0,
					max: 100,
					splitNumber: 8,
					axisLine: {
						lineStyle: {
							width: 40
						}
					},
					pointer: {
						show: false
					},
					axisTick: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisLabel: {
						show: false
					},
					title: {
						show: false
					},
					detail: {
						fontSize: 40,
						offsetCenter: [0, -3],
						valueAnimation: true,
						formatter: '{value}%',
						color: 'inherit'
					},
					data: [
						{
							value: E5Text.ScoreToPercent(value),
							name: 'Grade Rating'
						}
					]
				}
			]
		}
	}
});
