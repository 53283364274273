import React from "react";
import moment from "moment";
import * as Paper from "paper";
import Cytoscape from "cytoscape";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import { IReactionDisposer, reaction } from "mobx";
import ResizeSensor from "css-element-queries/src/ResizeSensor";
//
import { E5EntHStation } from "../../../entity/household/topo/E5EntHStation";
import { E5EntHEquip } from "../../../entity/household/topo/E5EntHEquip";
import { E5Store, E5StoreLangInfo } from "../../../store/E5Store";
import { E5MainConfig } from "../../../global/E5MainConfig"; //eslint-disable-line
import { E5UtilI18n } from "../../../global/E5MainLang";
import {
	E5TopologyLayoutTypeEnum, E5NetElementType, E5BandEnumIsEth, E5NodeTypeEnum, E5BandEnum
} from "../../../entity/E5Enums";
import {
	E5StoreHTopoSelected, E5StoreHStations, E5StoreHEquips, E5StoreHConnectivity, E5StoreH
} from "../../../store/E5StoreH";
//
import "./E5HTopology.css";
import DeviceInfo from "./DeviceInfo.component";
//
Cytoscape.use(require("cytoscape-cise"));
Cytoscape.use(require("cytoscape-cola"));
Cytoscape.use(require("cytoscape-dagre"));
Cytoscape.use(require("cytoscape-euler"));
Cytoscape.use(require("cytoscape-klay"));

//E5
export interface E5TopoLink {
	child: string;
	parent: string;
	isbackhaul: boolean;
	band: E5BandEnum;
	rssi: number;
	active: boolean;
}

//E5
interface E5TopologyItem {
	group: any;
	edgespoints: any[];
}

//E5
interface E5TopologyComplexEdge {
	path: any;
	sourceid: string;
	targetid: string;
}

//E5
interface E5HTopologyState {
	buildingtopology: boolean;
	layouttype: E5TopologyLayoutTypeEnum;
	topolinksmap: Map<number, E5TopoLink[]>;
	stepsready: boolean;
	timesteps: number[];
	curstep: number;
	selectedstep: number;
	selectednode: string;
}

//E5
interface E5HTopologyProps {
	langinfo: E5StoreLangInfo;
	selectedinfo: E5StoreHTopoSelected;
	equipinfo: E5StoreHEquips;
	stationinfo: E5StoreHStations;
	connectivityinfo: E5StoreHConnectivity;
}

//E5
export const E5HTopology = observer(class E5HTopology extends React.PureComponent<E5HTopologyProps, E5HTopologyState> {

	// ---------------- MEMBERS ----------------

	//E5
	cyref: React.RefObject<HTMLCanvasElement>;
	paperref: React.RefObject<HTMLCanvasElement>;
	mainref: React.RefObject<HTMLDivElement>;
	items: Map<string, E5TopologyItem>;
	complexedges: Map<string, E5TopologyComplexEdge>;
	tool?: any;
	cy?: Cytoscape.Core;
	slidertimer?: NodeJS.Timeout;

	// current paper state
	curhoveredid: string;
	curselectedid: string;
	resizetimeout?: NodeJS.Timeout;

	// current context
	curselectedkey: string;

	// maps & sets
	cy2topo: Map<string, E5EntHEquip | E5EntHStation>; // for a cy id, finds the node or device in topology
	topo2cy: Map<string, string>; // for elemtype+id, finds the nodeid in cy
	activeset: Set<string>;

	// autorun
	stop_change_topologies?: IReactionDisposer;
	stop_change_selected?: IReactionDisposer;

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HTopologyProps, state: E5HTopologyState) {
		super(props, state);
		this.state = {
			buildingtopology: false, layouttype: E5TopologyLayoutTypeEnum.Cola, topolinksmap: new Map(),
			stepsready: false, timesteps: [], curstep: 0, selectedstep: 0
		};
		this.cyref = React.createRef();
		this.paperref = React.createRef();
		this.mainref = React.createRef();
		this.items = new Map();
		this.complexedges = new Map();
		this.curhoveredid = this.curselectedid = this.curselectedkey = "";
		this.cy2topo = new Map();
		this.topo2cy = new Map();
		this.activeset = new Set();
	}

	//E5
	componentDidMount(): void {
		let cycontainer: HTMLCanvasElement | null = this.cyref.current;
		let papercontainer: HTMLCanvasElement | null = this.paperref.current;
		if (cycontainer !== null && papercontainer !== null) {
			// tell Paper to use and prepare our canvas
			Paper.setup(papercontainer);
			Paper.view.onResize = this.OnResize;
			this.tool = new Paper.Tool();
			this.tool.activate();
			this.tool.onMouseDown = this.OnMouseDown;
			this.tool.onMouseMove = this.OnMouseMove;

			// create topology graph using Cytoscape
			this.cy = Cytoscape({ container: cycontainer, headless: true });

			// build topology and select if any
			this.BuildTopology(() =>
				this.ChangeSelectedItem(this.props.selectedinfo.type + ":" + this.props.selectedinfo.id));
		}

		this.BuildTopologyTimeSteps();
		this.stop_change_topologies = reaction(() => this.props.connectivityinfo.topologies,
			() => this.BuildTopologyTimeSteps(() => this.BuildTopology(
				() => this.ChangeSelectedItem(this.curselectedkey))));
		this.stop_change_selected = reaction(
			() => this.props.selectedinfo.type + ":" + this.props.selectedinfo.id,
			() => this.ChangeSelectedItem(this.props.selectedinfo.type + ":" + this.props.selectedinfo.id));

		// resize detection (http://marcj.github.io/css-element-queries/)
		let curcompo = this;
		if (this.mainref.current !== null) {
			new ResizeSensor(this.mainref.current, function () {
				let papercanvas: HTMLCanvasElement | null = curcompo.paperref.current;
				if (papercanvas !== null)
					Paper.view.viewSize = new Paper.Size(papercanvas.clientWidth, papercanvas.clientHeight);
			});
		}
	}

	//E5
	componentWillUnmount(): void {
		// prevents resize callback from running after component got unmounted
		if (this.resizetimeout !== undefined)
			clearTimeout(this.resizetimeout);

		// also clean slider timer
		if (this.slidertimer !== undefined)
			clearTimeout(this.slidertimer);

		// probably useless (since cytoscape is headless) but harmless
		this.cy?.destroy();

		// clear Paper scene, tool and resize callback
		Paper.view.onResize = null;
		this.ClearPaperProject();
		if (this.tool !== undefined)
			this.tool.remove();

		this.stop_change_topologies?.();
		this.stop_change_selected?.();
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let { timesteps, curstep, stepsready } = this.state;
		let { Breadthfirst, Cise, Cola, Cose, Dagre, Euler, Klay } = E5TopologyLayoutTypeEnum;

		let sliderclass: string = timesteps.length === 0 || curstep < timesteps.length / 2 ?
			"label2right" : "label2left";

		let rssi2legend: string[][] = [
			["legendrssi rssired", "< -80"], ["legendrssi rssiorange", "-80-70"],
			["legendrssi rssigray", "-70-60"], ["legendrssi rssigreen", "> -60"]
		];

		let img2legend: string[][] = [
			["/img/topo/new/legend6ghz.svg", "6GHz"], ["/img/topo//new/legend5ghz.svg", "5GHz"],
			["/img/topo/new/legend2_4ghz.svg", "2.4GHz"], ["/img/topo/new/legendeth1000.svg", "ETH1000"],
			["/img/topo/new/legendeth100.svg", "ETH100"], ["/img/topo/new/legendeth.svg", "ETH"],
			["/img/topo/new/legendunknown.svg", E5UtilI18n._("wifih-topology-unknown-band")]
		];

		return <div className="e5wifitopology2" style={{ position: 'relative' }} ref={this.mainref}>
			<canvas className="e5wifitopo2inner" ref={this.cyref} />
			<canvas className="e5wifitopo2inner" ref={this.paperref} data-paper-resize />

			<div className="e5wifitopo2controls">
				<DeviceInfo
					selectedinfo={E5StoreH.Ins().selectedinfo}
					selectednode={this.state.selectednode}
					generateStationImageForTopology={this.generateStationImageForTopology}
					indicsysinfo={E5StoreH.Ins().indicsysinfo}
				/>
				{
					true &&
					<div className="controlsdesc">{E5UtilI18n._("wifih-topology-layouts")}</div>
				}

				{true && <BP.ButtonGroup>
					{this.GetLayoutBut(Breadthfirst, 1)}{this.GetLayoutBut(Cise, 2)}
					{this.GetLayoutBut(Cola, 3)}{this.GetLayoutBut(Cose, 4)}{this.GetLayoutBut(Dagre, 5)}
					{this.GetLayoutBut(Euler, 6)}{this.GetLayoutBut(Klay, 7)}
				</BP.ButtonGroup>}

				<div className="controlsdesc">{E5UtilI18n._("wifih-topology-slider")}</div>
				<BP.ButtonGroup>
					<BP.Button small={true} icon={"chevron-left"} onClick={this.SlideLeft}
						disabled={!stepsready || curstep <= 0} />
					<BP.Button small={true} icon={"chevron-right"} onClick={this.SlideRight}
						disabled={!stepsready || curstep >= timesteps.length - 1} />
				</BP.ButtonGroup>

				<BP.Slider className={sliderclass} disabled={!stepsready} labelRenderer={this.LabelStep} min={0}
					max={timesteps.length - 1} initialValue={curstep + 1} value={curstep}
					onChange={this.ChangeTime} onRelease={this.SelectStep} />
			</div>
			<div className="e5line-10 legend">
				<div>{E5UtilI18n._("wifih-topology-avg-rssi")}</div>
				{rssi2legend.map((arr, idx) => <div key={"rssi" + idx} className="e5line-5">
					<div className={arr[0]} />
					<div>{arr[1]}</div>
				</div>)}
				<BP.Divider />
				{img2legend.map((arr, idx) => <div key={"img" + idx} className="e5line-5">
					<img src={arr[0]} style={{ width: arr[0] === '/img/topo/legendunknown.svg' ? '10px' : '24px' }} alt="" />
					<div>{arr[1]}</div>
				</div>)}
			</div>
			{(this.state.buildingtopology || (E5StoreH.Ins().searchniinfo.status.loading && !stepsready)) &&
				<BP.NonIdealState className="e5wifitop2oinner" icon={<BP.Spinner intent={BP.Intent.PRIMARY} />} />}
		</div>;
	}

	//E5
	GetLayoutBut = (layouttype: E5TopologyLayoutTypeEnum, key: number): JSX.Element => <BP.Tooltip>
		<BP.Button key={key} text={key} onClick={() => this.ChangeLayout(layouttype)}
			intent={layouttype === this.state.layouttype ? BP.Intent.PRIMARY : BP.Intent.NONE} />
		{layouttype.charAt(0).toUpperCase() + layouttype.substring(1)}
	</BP.Tooltip>;

	// ---------------- EVENTS ----------------

	//E5
	ChangeLayout = (layouttype: E5TopologyLayoutTypeEnum): void =>
		this.setState({ layouttype: layouttype }, () => this.BuildTopology(
			() => this.ChangeSelectedItem(this.curselectedkey)));

	//E5
	ChangeTime = (value: number): void =>
		this.setState({ curstep: value }, () => {
			if (this.slidertimer !== undefined) clearTimeout(this.slidertimer);
			this.slidertimer = setTimeout(() => { this.SelectStep(value); }, 300);
		});

	//E5
	SlideLeft = (): void =>
		this.setState({ curstep: this.state.curstep - 1, selectedstep: this.state.curstep - 1 },
			() => this.BuildTopology(() => this.ChangeSelectedItem(this.curselectedkey)));

	//E5
	SlideRight = (): void =>
		this.setState({ curstep: this.state.curstep + 1, selectedstep: this.state.curstep + 1 },
			() => this.BuildTopology(() => this.ChangeSelectedItem(this.curselectedkey)));

	//E5
	SelectStep = (value: number): void => {
		if (this.slidertimer !== undefined) clearTimeout(this.slidertimer);
		if (value !== this.state.selectedstep)
			this.setState({ selectedstep: value > this.state.curstep ? this.state.curstep : value },
				() => this.BuildTopology(() => this.ChangeSelectedItem(this.curselectedkey)));
	};

	//E5
	RepositionTopology = (): void => {
		if (this.cy !== undefined && this.cy?.nodes()?.length !== 0) {
			if (this.cy?.pan().x === 0 && this.cy?.pan().y === 0) {
				this.ClearPaperProject();				

				this.setState({ buildingtopology: true }, () => {
					setTimeout(() => {
						this.cy?.resize();
					this.BuildTopology(() => this.ChangeSelectedItem(this.curselectedkey));
					}, 500);
				});
			}
		}
	};

	//E5
	OnResize = (): void => {
		if (this.resizetimeout !== undefined) clearTimeout(this.resizetimeout);
		this.resizetimeout = setTimeout(() => {
			if (this.cy !== undefined) {
				this.cy.resize();
				this.BuildTopology(() => this.ChangeSelectedItem(this.curselectedkey));
			}
		}, 300);
	};

	//E5
	OnMouseDown = (event: any): void => {
		if (event.point !== null && event.point.x !== null && event.point.y !== null) {
			let keyselected: string | undefined = undefined;
			for (let [id, item] of this.items) {
				if (!this.IsLegend(id) && item.group.position !== null &&
					item.group.position.x !== null && item.group.position.y !== null &&
					item.group.contains(event.point)) {
					let elem: E5EntHEquip | E5EntHStation | undefined = this.cy2topo.get(id);
					let key: string = "";
					if (elem instanceof E5EntHEquip) key = E5NetElementType.node + ":" + elem.imei;
					else if (elem instanceof E5EntHStation) key = E5NetElementType.device + ":" + elem.macaddress;
					this.OnSelectItem(key);
					keyselected = key;
				}
			}
			if (keyselected === undefined) this.OnDeselectItem();
		}
	};

	//E5
	OnMouseMove = (event: any): void => {
		if (event.point !== null && event.point.x !== null && event.point.y !== null)
			for (let [id, item] of this.items)
				if (!this.IsLegend(id) && item.group.children !== null)
					if (item.group.contains(event.point)) item.group.children[1].opacity = 0.3;
					else item.group.children[1].opacity = 0;
	};

	//E5
	OnSelectItem = (key: string): void => {
		if (this.topo2cy.size !== 0) {
			let oldkey: string = this.curselectedkey;
			this.ChangeSelectedItem(key);
			if (key !== oldkey) {// alert store if changed
				let newcyid: string | undefined = this.topo2cy.get(key);
				if (newcyid !== undefined) {
					let topoelem: E5EntHEquip | E5EntHStation | undefined = this.cy2topo.get(newcyid);
					if (topoelem instanceof E5EntHEquip)
						E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.node, topoelem, new E5EntHStation());
					else if (topoelem instanceof E5EntHStation)
						E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.device, new E5EntHEquip(), topoelem);
				}
			}
		}
	};

	//E5
	OnDeselectItem = (): void => {
		if (this.topo2cy.size !== 0) {
			let oldkey: string = this.curselectedkey;
			this.ChangeSelectedItem("");
			if ("" !== oldkey)// alert store if changed
				E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.none, new E5EntHEquip(), new E5EntHStation());
		}
	};

	// ---------------- UTILS ----------------

	//E5
	LabelStep = (value: number): string => {
		let { timesteps } = this.state, label: string = "";
		if (value === timesteps.length - 1) label = E5UtilI18n._("wifih-topology-fullday");

		else if (value === this.state.curstep) {
			let time: number = timesteps[value] * 1000;// seconds to milliseconds
			let timestr: string = moment(time).format("HH:mm:ss");
			if (value === timesteps.length - 2) {
				if (E5StoreH.Ins().curdateinfo.curdate < E5Store.Ins().todayinfo.today)
					label = timestr + " 🡆 " + E5UtilI18n._("wifih-topology-endofday");
				else label = timestr + " 🡆 " + E5UtilI18n._("wifih-topology-now");
			} else {
				let nexttime: number = timesteps[value + 1] * 1000;// seconds to milliseconds
				label = timestr + " 🡆 " + moment(nexttime).format("HH:mm:ss");
			}
		}
		return label;
	};

	//E5
	BuildTopologyTimeSteps(callback?: () => void) {
		let { loading, topologies } = this.props.connectivityinfo;
		let stepsready: boolean = !loading && topologies.length !== 0;

		// each time step corresponds to a position on the slider
		let timesteps: number[], timestepset: Set<number> = new Set();

		// each position on the slider has its list of topology links
		let topolinksmap: Map<number, E5TopoLink[]> = new Map();

		// gather every topology update time (both start and end)
		for (let topology of topologies) {

			for (let link of topology.links) {
				timestepset.add(link.start);
				if (link.end !== null) timestepset.add(link.end);
			}
		}
		// cast as array then sort times by ascending order
		timesteps = Array.from(timestepset);
		timesteps.sort((a: number, b: number) => { return a - b; });

		// append a fake time step that is the "full day" position at the far right of the slider
		timesteps.push(0);
		if (timesteps.length === 1)
			// when there is zero actual topology, a second fake time step is needed to push the handle to the right
			timesteps.push(0);

		// fill 
		let prevLinks: E5TopoLink[] = [];
		for (let timestep of timesteps) {
			let steplinks: E5TopoLink[] = [];
			for (let topology of topologies) {
				for (let link of topology.links)
					if (timestep >= link.start && (link.end === null || timestep < link.end)) {
						steplinks.push({
							child: topology.child, parent: topology.parent, isbackhaul: topology.isbackhaul,
							band: link.band, rssi: link.rssi, active: true
						});
						break;
					} else {
						steplinks.push({
							child: topology.child, parent: topology.parent, isbackhaul: topology.isbackhaul,
							band: link.band, rssi: link.rssi, active: false
						});
					}
			}
			if (steplinks.length === 0) {

				topolinksmap.set(timestep, steplinks);
			} else {
				topolinksmap.set(timestep, steplinks);
				prevLinks = steplinks;
			}

		}
		// build the aggregated topology for the "full day" position on the slider
		let daytopo: E5TopoLink[] = [], idx: number, dayset: Set<string> = new Set();
		topolinksmap.set(0, daytopo);
		const activeLinks: string[] = [];
		for (idx = timesteps.length - 1; idx >= 0; idx--) { //get keys of links that was active in past
			let links: undefined | E5TopoLink[] = topolinksmap.get(timesteps[idx]);
			if (links !== undefined) {
				for (let link of links) {
					if (link.active) {
						let key: string = link.child + ":" + link.parent;
						activeLinks.push(key)
					}
				}
			}
		}
		for (idx = timesteps.length - 1; idx >= 0; idx--) {
			let links: undefined | E5TopoLink[] = topolinksmap.get(timesteps[idx]);
			if (links !== undefined)
				for (let link of links) {
					let key: string = link.child + ":" + link.parent;
					if (!dayset.has(key)) {
						let daylink: E5TopoLink = Object.assign({}, link);
						daytopo.push(daylink);
						dayset.add(key);
						if (activeLinks.includes(key)) {
							daylink.active = true;
						}
					}
				}
		}

		// place the slider at the latest time step
		let time: number = timesteps.length - 1;

		this.setState({ stepsready, topolinksmap, timesteps, curstep: time, selectedstep: time }, callback);
	}

	//E5
	ChangeSelectedItem(key: string) {
		if (this.topo2cy.size !== 0) {
			let curcyid: string | undefined = this.topo2cy.get(this.curselectedkey);
			if (curcyid !== undefined) this.ShowOrHideSelectLayer(curcyid, false);
			// select new element
			let newcyid: string | undefined = this.topo2cy.get(key);
			if (newcyid !== undefined) this.ShowOrHideSelectLayer(newcyid, true);
			this.setState({ selectednode: key })
			this.curselectedkey = key;
		}
	}

	//E5
	BuildTopology = (callback: () => void): void =>// callback will be called at the end of full build
		this.setState({ buildingtopology: true }, () => {
			// Let React render NonIdealState and Spinner before Cytoscape's layout makes javascript busy
			setTimeout(() => {
				if (this.cy !== undefined) {
					this.ClearPaperProject();

					// Remove existing Cytoscape elements, add new ones, then run layout
					this.cy.remove("*");
					this.cy.add(this.GetElementsDataFromTopologyProp());
					if (this.props.connectivityinfo.topologies.length > 0) //cise layout crashes when topology is empty
						this.cy.layout(this.GetLayoutOptions()).run();
					// console.log(this.cy.nodes(),this.cy.edges())
					// Build Paper items from Cytoscape nodes data
					this.cy.nodes().forEach(this.BuildItem);

					// Build Paper edges from Cytoscape edges data
					this.cy.edges().forEach(this.BuildEdge);
					this.setState({ buildingtopology: false }, callback);
				}
			}, 0);
		});

	//E5
	ClearPaperProject = (): void => {
		if (Paper.project !== null) Paper.project.clear();
	};

	//E5
	GetElementsDataFromTopologyProp = (): Cytoscape.ElementDefinition[] => {
		let nodetypes: Map<string, string> = new Map(), idx: number;
		let time: number = this.state.timesteps[this.state.selectedstep];
		let selectedlinks: undefined | E5TopoLink[] = this.state.topolinksmap.get(time);
		let elements: Cytoscape.ElementDefinition[] = [], elementset: Set<string> = new Set();

		// empty maps & sets
		this.cy2topo = new Map();
		this.topo2cy = new Map();
		this.activeset = new Set();

		// add nodes
		for (idx = 0; idx < this.props.equipinfo.equips.length; idx++) {
			let node: E5EntHEquip = this.props.equipinfo.equips[idx];
			let cynodeid: string = node.imei + "*" + node.nodetype;
			nodetypes.set(node.imei, node.nodetype);
			if (this.state.stepsready && node.nodetype === E5NodeTypeEnum.gw) {
				elementset.add(cynodeid);
				this.activeset.add(cynodeid);
			}
			this.cy2topo.set(cynodeid, node);
			this.topo2cy.set(E5NetElementType.node + ":" + node.imei, cynodeid);
		}
		if (selectedlinks !== undefined && selectedlinks.length > 0) {
			let devicetypes: Map<string, string> = new Map();

			// add devices
			for (idx = 0; idx < this.props.stationinfo.stations.length; idx++) {
				let device: E5EntHStation = this.props.stationinfo.stations[idx];

				// update device band based on selected time
				let idxLink: number;
				for (idxLink = 0; idxLink < selectedlinks.length; idxLink++) {
					let link: E5TopoLink = selectedlinks[idxLink];

					if (link.active && link.child?.toLowerCase() === device.macaddress?.toLowerCase()) {
						device.band = link.band;
						break;
					}
				}

				let cynodeid: string = device.macaddress + "*" + device.devicetypecateg;
				this.cy2topo.set(cynodeid, device);
				this.topo2cy.set(E5NetElementType.device + ":" + device.macaddress, cynodeid);
				devicetypes.set(device.macaddress, device.devicetypecateg);
			}

			// add links
			let linkset: Set<string> = new Set();
			for (idx = 0; idx < selectedlinks.length; idx++) {

				let link: E5TopoLink = selectedlinks[idx];
				if (link.isbackhaul || link.parent === link.child) {
					//if (link.isbackhaul) {
					if (nodetypes.get(link.parent) !== undefined && nodetypes.get(link.child) !== undefined) {
						let source: string = link.parent + "*" + nodetypes.get(link.parent);
						let target: string = link.child + "*" + nodetypes.get(link.child);
						if (link.active) {
							this.activeset.add(source);
							this.activeset.add(target);
						}
						elementset.add(source);
						elementset.add(target);
						if (!linkset.has(target)) {
							linkset.add(target);
							let band: string = link.band;
							elements.push({
								data: {
									id: source + "->" + target + "@" + band + "§" + link.rssi + "%" + link.active,
									source: source, target: target
								}
							});
						}
					}
				} else {
					if (nodetypes.get(link.parent) !== undefined && devicetypes.get(link.child) !== undefined) {
						let source: string = link.parent + "*" + nodetypes.get(link.parent);
						let target: string = link.child + "*" + devicetypes.get(link.child);
						if (link.active) {
							this.activeset.add(source);
							this.activeset.add(target);
						}
						elementset.add(source);
						elementset.add(target);
						if (!linkset.has(target)) {
							linkset.add(target);
							let band: string = link.band;

							elements.push({
								data: {
									id: source + "->" + target + "@" + band + "§" + link.rssi + "%" + link.active,
									source: source, target: target
								}
							});
						}
					}
				}
			}
		}
		for (let cynodeid of elementset) elements.push({ data: { id: cynodeid } });
		// console.log(elements,this.cy2topo,this.topo2cy,this.activeset)
		return elements;

	};

	//E5
	GetLayoutOptions = (): Cytoscape.LayoutOptions => {
		let coordinate: number = 0, { layouttype } = this.state, options: Cytoscape.LayoutOptions = {
			name: layouttype, fit: true, padding: 50, animate: false, randomize: false, positions: () => {
				coordinate++;
				return { x: coordinate, y: coordinate };
			}
		};
		let { Cose, Euler } = E5TopologyLayoutTypeEnum;
		if (layouttype === Cose || layouttype === Euler) (options as any).randomize = true;
		return options;
	};

	//E5
	BuildItem = (cynode: Cytoscape.NodeSingular): void => {
		let dpr: number = window.devicePixelRatio;
		if (dpr === undefined || dpr === null || dpr < 0.1) dpr = 1;
		let group: any = new Paper.Group();
		let pos: Cytoscape.Position = cynode.renderedPosition();
		pos = { x: Math.round(pos.x), y: Math.round(pos.y) };// les icônes sont décalées parfois et on ne voit pas le bord droit ou gauche
		let id: string = cynode.id();
		let imgsource: string;
		const type = this.GetType(id);
		const macaddress = this.GetMacAddress(id);
		const indicglobalinfo = E5StoreH.Ins()?.indicglobalinfo;
		const fingerprint = indicglobalinfo.fingerprintresult.find((fingerprint) => {
			return fingerprint.childId === macaddress
		});
		const deviceType = fingerprint?.deviceType ? fingerprint?.deviceType : '-';
		if (type === E5NodeTypeEnum.ext) {
			imgsource = this.generateStationImageForTopology('ext')
		} else if (type === E5NodeTypeEnum.gw) {
			imgsource = this.generateStationImageForTopology('gw')
		} else {
			imgsource = this.generateStationImageForTopology(deviceType)
		}

		let img: any = new Paper.Raster(imgsource);
		let active: boolean = this.activeset.has(id);
		if (!active) img.opacity = 0.25;

		// default size to not display a big image when loading the topology and images.
		img.size = new Paper.Size(0, 0);

		// because the image is downloading from server, we need set size configuration
		// after the image is downloaded to set the correct size.
		img.onLoad = () => {
			// we have to fix the size of the raster because the imageurl is not downloaded immediately
			// due to img.scale() below, if we don't multiply imageurl width/height by dpr, the imageurl is perfect but a bit smaller depending on dpr value
			// if dpr is too big, we must multiply imageurl width/height by dpr, this will reset a good imageurl size, but it will be fuzzy again

			if (dpr < 2) {
				img.size = new Paper.Size(30, 30);
			} else {
				img.size = new Paper.Size(30 * dpr, 30 * dpr);
			} 
	
			img.smoothing = false;

			// scale by 1/dpr => resolve fuzzy imageurl on devices where dpr!=1
			img.scale(1 / dpr);
		};
		
		group.addChild(img);
		imgsource = "/img/nodes_and_devices/" + this.GetType(id) + "-black-full.png";
		let hoverlayer: any = new Paper.Raster(imgsource);

		hoverlayer.size = new Paper.Size(0, 0);

		hoverlayer.onLoad = () => {	
			if (dpr < 2) {
				hoverlayer.size = new Paper.Size(30, 30);
			} else {
				hoverlayer.size = new Paper.Size(30 * dpr, 30 * dpr);
			}
			
			hoverlayer.smoothing = false;
			hoverlayer.opacity = 0;
			hoverlayer.scale(1 / dpr);
		}
		
		group.addChild(hoverlayer);
		let selectlayer: any = new Paper.Path.Circle({ center: [0, 0], radius: 20 });
		selectlayer.fillColor = new Paper.Color("#000000");
		selectlayer.opacity = 0;
		group.addChild(selectlayer);
		group.translate(new Paper.Point(pos.x, 35 + pos.y * 0.99));
		this.items.set(id, { group: group, edgespoints: [] });
	};

	//E5
	BuildEdge = (cyedge: Cytoscape.EdgeSingular): void => {
		let edgeid: string = cyedge.id();
		let sourceid: string = cyedge.source().id(), targetid: string = cyedge.target().id();
		let rssi: number = parseInt(edgeid.split("§")[1].split("%")[0]);
		let active: string = edgeid.split("%")[1], halfopacity: boolean = active !== "true";
		let activeLine: boolean = !this.activeset.has(targetid);
		let band: E5BandEnum = this.GetBand(edgeid) as E5BandEnum;
		if (E5BandEnumIsEth(band) || band === E5BandEnum.none)
			this.BuildTrivialEdge(sourceid, targetid, this.GetEdgeColor(rssi, activeLine), band);
		else this.BuildComplexEdge(edgeid, sourceid, targetid, this.GetEdgeColor(rssi, activeLine));
	};

	//E5
	BuildTrivialEdge = (sourceid: string, targetid: string, color: string, band: E5BandEnum): any => {
		let path: any = new Paper.Path({ strokeColor: color });
		if (band === E5BandEnum.eth100) path.dashArray = [8, 4];
		else if (band === E5BandEnum.eth) path.dashArray = [8, 3, 2, 3];
		else if (band === E5BandEnum.none) path.dashArray = [1, 3];
		path.sendToBack();
		let source: E5TopologyItem | undefined = this.items.get(sourceid);
		let target: E5TopologyItem | undefined = this.items.get(targetid);
		if (source !== undefined && source.group.position !== null && target !== undefined &&
			target.group.position !== null) {
			path.moveTo(source.group.position);
			path.lineTo(target.group.position);
			if (path.firstSegment.point !== null && path.lastSegment.point !== null) {
				source.edgespoints.push(path.firstSegment.point);
				target.edgespoints.push(path.lastSegment.point);
			}
		}
		return path;
	};

	//E5
	BuildComplexEdge = (edgeid: string, sourceid: string, targetid: string, color: string): void => {
		let edge: E5TopologyComplexEdge | undefined = this.complexedges.get(edgeid);
		edge?.path.remove();
		this.complexedges.set(edgeid,
			{ path: new Paper.CompoundPath({ strokeColor: color }), sourceid: sourceid, targetid: targetid });
		edge = this.complexedges.get(edgeid);
		if (edge !== undefined) {
			edge.path.sendToBack();
			let sourceitem: E5TopologyItem | undefined = this.items.get(sourceid);
			let targetitem: E5TopologyItem | undefined = this.items.get(targetid);
			if (sourceitem !== undefined && targetitem !== undefined) {
				let source: any = sourceitem.group.position, target: any = targetitem.group.position;
				if (source !== null && target !== null && source.x !== null && source.y !== null && target.x !== null
					&& target.y !== null) {
					let idx: number;
					if (edge.path.children !== null)
						for (idx = 0; idx < edge.path.children.length; idx++)
							if (edge.path.children[idx] !== undefined)
								(edge.path.children[idx]).removeSegments();
					edge.path.removeChildren();
					let dx: number = target.x - source.x, dy: number = target.y - source.y;
					let norm: number = Math.sqrt(dx * dx + dy * dy);
					edge.path.moveTo(source);
					let step: number = Math.PI * 2;
					let length: number = norm *
						(this.GetBand(edgeid) === E5BandEnum.freq6ghz ? 3 :
							(this.GetBand(edgeid) === E5BandEnum.freq5ghz ? 2 : 1));
					let amplitude: number = 6 / norm;
					for (idx = 0; idx < length; idx += step) {
						let x: number = source.x + idx * dx / length;
						let y: number = source.y + idx * dy / length;
						let sin: number = Math.sin(idx / 3) * amplitude;
						edge.path.lineTo(new Paper.Point(x + dy * sin, y - dx * sin));
					}
					edge.path.smooth();
				}
			}
		}
	};

	//E5
	ShowOrHideSelectLayer = (id: string, doshow: boolean): void => {
		let item: E5TopologyItem | undefined = this.items.get(id);
		if (item !== undefined && item.group.children !== null) {
			//item.group.bringToFront();
			let lastopacity: number | null = item.group.children[2].opacity;
			if (lastopacity !== null)
				item.group.children[2].set({ opacity: doshow ? 0.33 : 0 });
		}
	};

	//E5
	IsLegend = (id: string): boolean =>
		id.split("#")[0] === "legend";

	//E5
	GetType = (id: string): string => {
		let type: string = id.split("*")[1];
		if (type === E5NodeTypeEnum.none) type = "default";
		return type;
	};
	generateStationImageForTopology = (deviceType: string) => {
		let deviceImgName = '';
		switch (deviceType) {
			case 'Smart device':
				deviceImgName = 'Smart_Device'
				break
			case 'Third Party AP':
				deviceImgName = 'Third_Party_AP'
				break
			case 'Printer':
				deviceImgName = 'printer'
				break
			case 'Computer':
				deviceImgName = 'laptop'
				break
			case 'Smartphone or tablet':
				deviceImgName = 'phone'
				break
			case 'Sound device':
				deviceImgName = 'soundsystem'
				break
			case 'Game console':
				deviceImgName = 'gameconsole'
				break
			case 'Multimedia device':
				deviceImgName = 'miltimedia-device'
				break
			case 'Wifi equipment':
				deviceImgName = 'wifi-equipment'
				break
			case 'IoT device':
				deviceImgName = 'IOT'
				break
			case 'Unknown':
				deviceImgName = "unknown"
				break
			case 'ext':
				deviceImgName = "extender"
				break
			case 'gw':
				deviceImgName = 'gateway'
				break
			default:
				deviceImgName = "unknown"
				break
		}

		return `/img/nodes_and_devices/devices-by-fingerprint/topology/${deviceImgName}.png`;
	}
	//E5
	GetMacAddress = (id: string): string => {
		let macaddress: string = id.split("*")[0];
		return macaddress;
	};
	GetBand = (edgeid: string): string =>
		edgeid.split("@")[1].split("§")[0];

	//E5
	GetEdgeColor = (rssi: number, halfopacity: boolean): string => {
		let color: string = "#43A047";//green
		if (rssi <= -60 && rssi > -70) color = "#D4E157";//lemon
		else if (rssi <= -70 && rssi > -80) color = "#FFA726";//orange
		else if (rssi <= -80) color = "#FF0000";//red
		if (halfopacity) color += "44";
		return color;
	};
});
