import {E5EntHMetSys} from "./E5EntHMetSys";
import {E5Entity} from "../../E5Entity";
import {E5Text} from "../../../util/E5Text";

//E5
export class E5EntHMetSysCpuMem extends E5EntHMetSys {
	uptime: number|undefined = undefined;
	load1: number|undefined = undefined;
	load5: number|undefined = undefined;
	load15: number|undefined = undefined;
	memtotal: number|undefined = undefined;
	memfree: number|undefined = undefined;
	memcached: number|undefined = undefined;
	memcommit: number|undefined = undefined;
	cpuSystem: number|undefined = undefined;
	cpuUsage: number|undefined = undefined;
	cpuUser: number|undefined = undefined;

	//E5
	constructor(json?: any) {
		super();
		if (json !== undefined) this.CopyFromJson(json);
	}

	//E5
	CopyFromJson(json: any): void {
		super.CopyFromJson(json);
		this.uptime = E5Entity.ParseIntUndef(json.uptime);

		this.load1 = E5Entity.ParseFloatUndef(json.load1);
		this.load5 = E5Entity.ParseFloatUndef(json.load5);
		this.load15 = E5Entity.ParseFloatUndef(json.load15);

		this.cpuSystem = E5Entity.ParseFloatUndef(json.cpu_global.cpu_system);
		this.cpuUsage = E5Entity.ParseFloatUndef(json.cpu_global.cpu_usage);
		this.cpuUser = E5Entity.ParseFloatUndef(json.cpu_global.cpu_user);

		this.memtotal = E5Entity.ParseIntUndef(json.total_memory);
		if (this.memtotal !== undefined) this.memtotal = E5Text.RoundUp(this.memtotal / 1024);//KB=>MB
		this.memfree = E5Entity.ParseIntUndef(json.free_memory);
		if (this.memfree !== undefined) this.memfree = E5Text.RoundUp(this.memfree / 1024);//KB=>MB
		this.memcached = E5Entity.ParseIntUndef(json.cached_memory);
		if (this.memcached !== undefined) this.memcached = E5Text.RoundUp(this.memcached / 1024);//KB=>MB
		this.memcommit = E5Entity.ParseIntUndef(json.memory_committed_as);
		if (this.memcommit !== undefined) this.memcommit = E5Text.RoundUp(this.memcommit / 1024);//KB=>MB
	}

	//E5
	CopyToJson(): any {
		return {
			...super.CopyToJson(), uptime: this.uptime, load1: this.load1, load5: this.load5, load15: this.load15,
			total_memory: this.memtotal !== undefined ? this.memtotal * 1024 : undefined,//MB=>KB
			free_memory: this.memfree !== undefined ? this.memfree * 1024 : undefined,//MB=>KB
			cached_memory: this.memcached !== undefined ? this.memcached * 1024 : undefined,//MB=>KB
			memory_committed_as: this.memcommit !== undefined ? this.memcommit * 1024 : undefined,//MB=>KB
			cpuSystem: this.cpuSystem, cpuUsage: this.cpuUsage, cpuUser: this.cpuUser
		};
	}
}
